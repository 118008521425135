import React from 'react'
const SvgClose = () => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 1000 1000'
    >
      <g>
        <path d='M500,10C229.4,10,10,229.4,10,500c0,270.7,219.4,490,490,490c270.7,0,490-219.3,490-490C990,229.4,770.7,10,500,10z M500,924.7C265.8,924.7,75.3,734.2,75.3,500S265.8,75.3,500,75.3S924.7,265.8,924.7,500S734.2,924.7,500,924.7z' />
        <path d='M680.8,273L501.3,452.6L340.2,273c-12.7-12.7-33.5-12.7-46.2,0c-12.7,12.7-12.7,33.5,0,46.2l161.1,179.6L273,680.8c-12.7,12.7-12.7,33.5,0,46.2c12.7,12.7,33.5,12.7,46.2,0l179.6-179.5L659.9,727c12.7,12.7,33.5,12.7,46.1,0c12.7-12.7,12.7-33.5,0-46.2L544.9,501.3L727,319.2c12.7-12.7,12.7-33.5,0-46.2S693.6,260.2,680.8,273z' />
      </g>
    </svg>
  )
}
export default SvgClose
