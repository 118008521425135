import React from 'react'
const SvgDownload = () => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 1000 1000'
    >
      <g>
        <path d='M622.5,173.3h163.3c33.8,0,62.7,12,86.6,35.9s35.9,52.8,35.9,86.6v571.7c0,33.8-12,62.7-35.9,86.6S819.6,990,785.8,990H214.2c-33.8,0-62.7-12-86.6-35.9s-35.9-52.8-35.9-86.6V295.8c0-33.8,12-62.7,35.9-86.6s52.8-35.9,86.6-35.9h163.3V255H214.2c-11.3,0-20.9,4-28.9,12c-8,8-12,17.6-12,28.9v571.7c0,11.3,4,20.9,12,28.9c8,8,17.6,12,28.9,12h571.7c11.3,0,20.9-4,28.9-12c8-8,12-17.6,12-28.9V295.8c0-11.3-4-20.9-12-28.9c-8-8-17.6-12-28.9-12H622.5V173.3z M500,10c11.3,0,20.9,4,28.9,12c8,8,12,17.6,12,28.9v513.9l93.5-93.8c7.9-7.9,17.5-11.8,29-11.8c11.7,0,21.4,3.9,29.2,11.6c7.8,7.8,11.6,17.5,11.6,29.2c0,11.5-3.9,21.2-11.8,29L529,692.4c-7.9,7.9-17.5,11.8-29,11.8c-11.5,0-21.2-3.9-29-11.8L307.6,529c-7.9-8.3-11.8-18-11.8-29c0-11.3,4-20.9,12-28.9c8-8,17.6-12,28.9-12c11.5,0,21.2,3.9,29,11.8l93.5,93.8V50.8c0-11.3,4-20.9,12-28.9C479.1,14,488.7,10,500,10L500,10z' />
      </g>
    </svg>
  )
}

export default SvgDownload
